<template>
  <div class="main">
      <img src="../assets/img/fabrica.jpg" width="101%"  alt="fabrica">
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
// 
@media screen and (max-width: 442px) {
  .main {
  margin-top:0.7%;
}
}
</style>
